<template>
  <v-container
    ><h4>Some of the things I've worked on</h4>
    <div style="padding: 10px 0px">
      1. Slide (my startup from college - website was formerly slideapp.dev but
      that has now been decommissioned. Reach out on
      <a href="https://www.linkedin.com/in/mattlichtinger" target="_blank"
        >LinkedIn</a
      >
      if you wanna chat about it or see a local demo)
    </div>
    <div style="padding: 10px 0px">
      2.
      <a href="https://mitchell-s-website.firebaseapp.com" target="_blank"
        >Brother's Website
      </a>
      {{ "\xa0" }}(never fully finished due to lack of pictures/text from him)
    </div>
    <div style="padding: 10px 0px">
      3.
      <a href="https://eecs351-final-project.web.app/" target="_blank"
        >Signal Processing
      </a>
      {{ "\xa0" }}(college class project)
    </div>
    <div style="padding: 10px 0px">
      4.
      <a href="https://liveshopper.com" target="_blank"
        >Liveshopper Sassie Website Redesign
      </a>
      {{ "\xa0" }}(summer internship project)
    </div>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<style scoped>
* {
  /* border: 1px solid black; */
}
</style>
